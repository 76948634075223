
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 91, 135, 0.7), rgba(0, 91, 135, 0.7));
}




.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  background: rgb(0,91,135);
  background: radial-gradient(circle, rgb(6, 85, 125) 0%, rgba(38,75,112,1) 55%, rgb(7, 76, 111) 100%); 
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.9s;
  transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background: rgb(0,91,135);
  background: radial-gradient(circle, rgb(6, 85, 125) 0%, rgba(38,75,112,1) 55%, rgb(7, 76, 111) 100%); 
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

}

.flip-card-back {
  background: rgb(0, 91, 135);
  background: radial-gradient(circle, rgb(6, 85, 125) 0%, rgba(38,75,112,1) 55%, rgb(7, 76, 111) 100%); 
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

  color: white;
  transform: rotateY(180deg);
}
