
#drowItem {

  background: 
    linear-gradient(90deg,#08638F 50%,#005B87 0) 
    var(--_p,100%)/200% no-repeat !important;
  -webkit-background-clip: text;
          background-clip: text;
  transition: .7s;
  text-decoration: none;
}
#drowItem:hover {
  --_p: 0%;
}








#navlink:focus {

    border-style: solid;

  }

  #link{
    text-decoration: none;
    text-decoration: none; /* Remove underline */
    position: relative; /* Required for absolute positioning */
    transition: color 0.3s; /* Smooth color transition */
  }


#link {
  position: relative;
  padding:5px 20px;
}
#link::after {
  content: ' ';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 0;
  background: rgb(255, 191, 0);
  transition: height 0.3s;
}
#link:hover::after {
  height: 5px;
  transition: height 0.3s;
}

#link + #link::after {
  content: ' ';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 5px;
  background: rgb(255, 191, 0);
  transition: width 0.3s;
}

#link + #link:hover::after {
  width: 100%;
  transition: width 0.3s;
}