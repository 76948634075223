@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



body {
  background: rgb(0,91,135);
  background: radial-gradient(circle, rgba(0,91,135,1) 0%, rgba(38,75,112,1) 55%, rgb(6, 58, 84) 100%); 
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.adsbygoogle {
	display: block;
	margin: 10px auto;
  }