#partnerdiv{
margin-top: 10%;

border: 1px solid rgba(17, 125, 208, 0.054);
}
#cercle{
    
    border-radius: 50%;
    padding: 10%;
    background: linear-gradient(90deg, rgba(0, 90, 135, 0.048) 0%, rgba(0, 90, 135, 0.655) 53%, rgba(0, 90, 135, 0.021) 100%);
    backdrop-filter: blur(60px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .01);


}

#cercle {
    color: #fff;
    text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #bc13fe,
      0 0 82px #bc13fe,
      0 0 92px #bc13fe,
      0 0 102px #bc13fe,
      0 0 151px #bc13fe;
  }
    

    
  #cercle  {
  



    box-shadow: 
              0 0 0.8rem #0e8ccb,
              0 0 2.8rem #08638F,
              inset 0 0 1.3rem #08638F; 
  }
  
  h2{
    text-align:center;
    padding: 20px;
  }
