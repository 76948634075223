#cardwhy {
   

    background-color:  rgba(0, 90, 135, 0.297)!important ;
    background: linear-gradient(#FFBB00 0 0) no-repeat calc(200% - var(--p, 0%))
      100% / 220% var(--p, 0.5em);
    transition: 0.3s var(--t, 0s),
      background-position 0.6s calc(0.8s - var(--t, 0s));
      color: #fff;

   backdrop-filter: blur(60px);
   border: 1px solid rgba(17, 125, 208, 0.25);
   box-shadow: 0 10px 20px rgba(0, 0, 0, .01);
   border-radius: 10px;
  }
  #cardwhy:hover {
    --p: 100%;
    --t: 0.4s;
    color: #232323;
  }
  

  #cardwhycontent {
   


    background: linear-gradient(90deg, rgba(0, 90, 135, 0.048) 0%, rgba(0, 90, 135, 0.655) 53%, rgba(0, 90, 135, 0.021) 100%);
   backdrop-filter: blur(60px);
   box-shadow: 0 10px 20px rgba(0, 0, 0, .01);
   border-radius: 10px;
margin-right: 10%;
  }
  #cardwhycontent:hover {
 
    color: #232323;
  }
  

 
  