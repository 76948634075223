#footer{
    background-color:  rgba(0, 90, 135, 0.297)!important ;

    transition: 0.3s var(--t, 0s),
      background-position 0.6s calc(0.8s - var(--t, 0s));
      color: #fff;

   backdrop-filter: blur(60px);
   border: 1px solid rgba(17, 125, 208, 0.17);
   box-shadow: 0 10px 20px rgba(0, 0, 0, .01);

   
}