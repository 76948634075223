#contact {
   


    background: linear-gradient(90deg, rgba(0, 90, 135, 0.048) 0%, rgba(0, 90, 135, 0.655) 53%, rgba(0, 90, 135, 0.021) 100%);
   backdrop-filter: blur(60px);
   box-shadow: 0 10px 20px rgba(0, 0, 0, .01);
   border-radius: 10px;
  }

